import '../../style/style.css';

function NotFound() {

    return(
        <div>
            <h1>Página não encontrada.</h1>
        </div>
    );
    
}

export default NotFound;